import React from 'react';
import { Grid } from '@material-ui/core';
export default function App() {
    return (
        <Grid container 
          style={{backgroundColor: ''}}
          justify="space-around"
          alignItems="center"
          className="GroupPage_Content_Row" 
          xs={12}
          >
<h3>
    Merch will go here
</h3>
<p>
<h3>Silhouette Album by Bohemian Quartet</h3>

https://www.amazon.com/gp/offer-listing/B00HPCJIPO/ref=tmm_acd_used_olp_0?ie=UTF8&condition=used

<h3>Beyond Tradition by Bohemian Quartet</h3>

https://www.amazon.com/Beyond-Tradition-Bohemian-Quartet/dp/B01K8KZW2M/ref=cm_cr_arp_d_product_top?ie=UTF8
</p>


          </Grid>
    );
}